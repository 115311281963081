import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { graphql, StaticQuery } from "gatsby";
import Link from 'gatsby-link';
import React from "react";
import Headroom from 'react-headroom';
import { Cell, ContainerScale, Row } from '../grid/grid.component';
import { responsiveFont, settings } from "../grid/settings";
;

class Header extends React.Component {
  render() {

    return (
      <StaticQuery
        query={graphql`
          query {
            settings: prismicWebsiteSettings {
              data{
                nav_links {
                  text
                  link {
                    uid
                  }
                }
              }
            }
          }
        `
        }
        render={data => {
          const {
            nav_links

          } = data.settings.data;
          return (
            <Headroom>
              <HeaderWrapper color={this.props.color}>
                <ContainerScale className="container">
                  <Row>
                    <Cell>
                      <Logo><Link to={'/'}>Karimoku Case study</Link></Logo>
                    </Cell>
                    <Cell css={cell_right}>
                      <MenuWrapper>
                        <ul>
                          {nav_links.map(link => (
                            <li key={link.text}><Link to={`/${link.link.uid === 'index' ? '' : link.link.uid}`}

                            >{link.text}</Link></li>
                          ))}
                        </ul>
                      </MenuWrapper>
                    </Cell>
                  </Row>
                </ContainerScale>
              </HeaderWrapper>
            </Headroom>
          )
        }
        }
      />

    )
  }
}
export default Header


const HeaderWrapper = styled.header`
  width: 100%;
  z-index: 1000;

  color: ${props => props.color || '#242423'};
  a{
    color: ${props => props.color || '#242423'};
    text-decoration: none;
    display: inline-block;
  }


  .container{
    /* max-width: initial; */
  }

  opacity: 1;
  transition: opacity 500ms;
  /* .tl-edges > div:nth-last-of-type(2) & {
    transition: opacity 0ms;
    opacity: 0;
  } */
  .tl-wrapper-status--entering & {
    transition: opacity 0ms;
    opacity: 0;
  }
`;

const cell_right = css`
  align-self: center;
  text-align: right;
`;

const Logo = styled.div`
  text-transform: uppercase;
  a{
    padding: 37px 0 0 0;
    letter-spacing: 0.157rem;

    @media (max-width: ${settings.baseWidth - 1}px){
      padding-left: 0;
    }
  }
  ${responsiveFont(settings.typo.logo)};
`;
const MenuWrapper = styled.div`

  @media (max-width: ${settings.ipadWidth - 1}px){
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    display: none;
  }
  
  ul{
    width: 100%;
    align-self: center;

    font-size: ${settings.typo.nav.max}px;
    line-height: ${settings.typo.nav.height};
    margin: 0;
    padding: 0;
    :hover{
      a{
        /* opacity: .8; */
      }
    }
    li{

      display: inline;
        
      &:last-of-type a{
        padding-right: 0;
        @media (min-width: ${settings.baseWidth}px){
          padding-right: 0;
        }
      }
    }
    a{
      padding: 37px 25px;
      &:hover{
        /* opacity: 1; */
      }
    }

    @media (max-width: ${settings.ipadWidth - 1}px){
      
      font-size: 22px;
      line-height: ${settings.typo.nav.height};

      a{
        padding: 17px 0;
        display: block;
        text-align: center;
      }
    }
  }
`;
