import styled from "@emotion/styled";
import React from "react";
import Helmet from 'react-helmet';
import { fonts, responsiveFont, responsiveWidth, settings } from '../components/grid/settings';
import Header from '../components/header/header';
import "./layout.css";

class LayoutComponent extends React.Component {

  shouldComponentUpdate() {
    this.setTopPosition();
    return true;
  }
  setTopPosition() {
    if (document) {
      const exitDiv = document.querySelector('.tl-wrapper > div');
      if (window.pageYOffset > 0) {
        if (exitDiv) { exitDiv.style.top = `-${window.pageYOffset}px`; }
      }
      if (!exitDiv) console.log('NO DIV');
    }
  }

  render() {
    const { children } = this.props


    console.log('Render layout');
    // return (
    //   <LayoutWrapper>
    //     {children}
    //   </LayoutWrapper>
    // )

    return (
      <LayoutWrapper>
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <main>
          <Header color="" />

          {children}

        </main>
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      </LayoutWrapper>
    )
  }
}

export default LayoutComponent



const LayoutWrapper = styled.div`


  
  white-space: pre-line;


  @media (max-width: ${settings.mobileWidth - 1}px){
    white-space: normal;
  }

  p{
    ${responsiveFont(settings.typo.p)};
  }
  h1{
    ${responsiveFont(settings.typo.headline)};
    text-transform: uppercase;
    letter-spacing: 1.2vw; 
    /* text-align: center; */
    margin-bottom: 7vw;
    font-family: ${fonts.karimoku};
  }
  h2{
    ${responsiveFont(settings.typo.h2)};
  }
  h3{
    ${responsiveFont(settings.typo.h3)};
    text-transform: uppercase;
    letter-spacing: .4vw; 
    
  }
  p + h3{
    margin-top: 5.5vw;
  }
  .intro{
    font-style: italic;
    text-align: center;
    margin-bottom: 3vw;
  font-family: ${fonts.sabon};
    ${responsiveFont(settings.typo.intro)};
  }
  .credits{
    color: #6E6E6E;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 6vw;
  }
  .caption, .caption-on-side{
    max-width: 80%;
    margin: 0 auto;
    margin-top: 25px;
    text-align: center;
    font-family: ${fonts.sabon};
    ${responsiveFont(settings.typo.caption)};

    padding: 0;
    margin-bottom: 4vw;
  }
  .caption:last-of-type, .caption-on-side:last-of-type{
    margin-bottom: 0;
  }
  .caption-left{
    text-align: left;
  }
  .caption-right{
    text-align: right;
  }

  .quote{
  font-family: ${fonts.sabon};
    font-style: italic;
    text-align: center;
    margin-bottom: 3vw;
    ${responsiveFont(settings.typo.quote)};
  }
  .quote-large{
  font-family: ${fonts.sabon};
    font-style: italic;
    text-align: center;
    margin-bottom: 3vw;
    ${responsiveFont(settings.typo.quotelarge)};
  }
  .quote-source{
    text-transform: uppercase;
    text-align: center;
  }

  .block-img{
    font-size: 0;
    position: relative;
    display: inline-block;
    min-width: 100%;

    img{
      width: 100%;
      max-width: 100%;
    }

    .caption-on-side{
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      width: 29%;
      padding: 0 27px;
      padding: 0 5.5%;
      text-align: right;
      margin: 0;
    }
  }
  .quote, .quote-large, .quote-source, .caption, .caption-on-side{
    margin-bottom: 1.75vw;
  }
  .caption, .caption-on-side{
    margin-top: 1.75vw;
    ${responsiveWidth('margin-bottom', settings.spacers.min, settings.spacers.max)}
  }
  p{
    padding: 0;
  }
  p:empty{
    display: none;
  }
  p:first-of-type{
    margin-top: 0;
  }
  p:last-of-type{
    margin-bottom: 0;
  }



  .tl-edges > div:nth-last-of-type(2) {
    
    div{
      /* opacity: 0; */
    }

    .image-hero-wrapper{
      /* opacity: 1; */
    }
  }

  .default-fade{
    opacity: 0;
    transition: opacity 1000ms;
  }

  .tl-wrapper-status--exiting .default-fade{
    opacity: 0;
  }
  .tl-wrapper-status--entered .default-fade{
    opacity: 1;
  }
`;