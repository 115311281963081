
// Settings
export const settings = {
  baseWidth: 1440,
  scaleAboveDesktop: false,
  useRem: false,
  mobileWidth: 768,
  ipadWidth: 1024,
  grid: {
    rows: 12,
    containerMaxWidth: 1140,
    containerMargin: 50,
    containerMarginMin: 30
  },
  spacers: {
    count: 4,
    min: 100,
    max: 200
  },
  gutter: {
    count: 4,
    min: 20,
    max: 30
  },
  typo: {
    h1: {
      min: 70 * .6,
      max: 70,
      height: 1.2,
      weight: 600,
    },
    h2: {
      min: 19,
      max: 37,
      height: 1.55,
      weight: 300,
    },
    h3: {
      min: 20,
      max: 23,
      height: 1,
      weight: 300,
    },
    h4: {
      min: 30 * .6,
      max: 30,
      height: 1,
      weight: 300,
    },
    h5: {
      min: 11,
      max: 23,
      height: 1,
      weight: 300,
    },
    h6: {
      min: 12,
      max: 12,
      height: 1,
      weight: 300,
    },
    headline: {
      min: 40,
      max: 48,
      height: 1.3,
      weight: 300,
    },
    p: {
      min: 16,
      max: 18,
      height: 1.55,
      weight: 300,
    },
    nav: {
      min: 12,
      max: 12,
      height: 2,
      weight: 400,
    },
    small: {
      min: 12,
      max: 12,
      height: 2,
      weight: 400,
    },
    specs: {
      min: 10,
      max: 12,
      height: 2,
      weight: 400,
    },
    dealer: {
      min: 14,
      max: 18,
      height: 1.45,
      weight: 400,
    },
    dealerHeader: {
      min: 12,
      max: 14,
      height: 1.575,
      weight: 400,
    },
    intro: {
      min: 18,
      max: 23,
      height: 1.35,
      weight: 400,
    },
    titleintro: {
      min: 16,
      max: 18,
      height: 1.55,
      weight: 400,
    },
    caption: {
      min: 12,
      max: 14,
      height: 1.575,
      weight: 400,
    },
    quote: {
      min: 20,
      max: 23,
      height: 1.4,
      weight: 400,
    },
    quotelarge: {
      min: 30,
      max: 35,
      height: 1.35,
      weight: 400,
    },
    logo: {
      min: 12,
      max: 12,
      height: 2,
      weight: 400,
    },
    input: {
      min: 16,
      max: 20,
      height: 1.3,
      weight: 300,
    }
  }
}

export const colors = {
  beigedark: '#EEECE3'
}

export const fonts = {
  karimoku: '"Karimoku001", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;',
  sabon: '"Sabon LT Std"'
}


// Helper functions
const fontSize = size => settings.useRem ? `${size / 16}rem` : `${size}px`;
const fontSizeInBetween = size => settings.useRem ? `calc(1rem + ${(size - 16) / settings.baseWidth * 100}vw)` : `${size / settings.baseWidth * 100}vw`;

/*

letter-spacing: x.xxem;
word-spacing: x.xxem;
text-transform: uppercase;

text-rendering: optimizeLegibility

*/
export const responsiveFont = ({ min, max, height, weight }) => `
    font-size: ${fontSize(min)};
    line-height: ${height};
    font-weight: ${weight};
    // margin-top: 1.6rem;
    // margin-bottom: .8rem;

    @media (min-width: ${min / (max / settings.baseWidth * 100) * 100}px) {
        font-size: ${fontSizeInBetween(max)};
    }
    ${settings.scaleAboveDesktop === true ? `
    ` : `
    @media (min-width: ${settings.baseWidth}px) {
        font-size: ${fontSize(max)};
    }
    `}
`;

export const responsiveParam = (param, min, max) => `
    ${param}: 0 ${min}px;
    
    @media (min-width: ${min / (max / settings.baseWidth * 100) * 100}px) {
        ${param}: 0 ${max / settings.baseWidth * 100}vw;
    }
    ${settings.scaleAboveDesktop === true ? `
    ` : `
    @media (min-width: ${settings.baseWidth}px) {
        ${param}: 0 ${max}px;
    }
    `}
`;
export const responsiveWidth = (param, min, max) => `
    ${param}: ${min}px;
    
    @media (min-width: ${min / (max / settings.baseWidth * 100) * 100}px) {
        ${param}: ${max / settings.baseWidth * 100}vw;
    }
    ${settings.scaleAboveDesktop === true ? `
    ` : `
    @media (min-width: ${settings.baseWidth}px) {
        ${param}: ${max}px;
    }
    `}
`;

